.row-header{
  margin:0px auto;
  padding:0px auto;
}

.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}

th, td {
  padding: 0px 15px 0px 10px;
}

.full-width {
  left: 50%; 
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100 vw;
}

.footer{
  background-color:  white;
  margin:0px auto;
  padding: 20px 0px 20px 0px;
  color:black;
}


.jumbotron {
  padding:70px 30px 70px 30px;
  margin:0px auto;
  background:  floralwhite;
  color:rgb(133, 229, 240);
}

address{
  font-size:80%;
  margin:0px;
  color:#0f0f0f;
}

.bg{
  width: 100%;
  z-index: 0;
}

.navbar {
  background-color: white;

}
.navbar-dark .navbar-nav .nav-link{
  color: grey;
  font-size: 1.2em;
}

.navbar-dark .navbar-nav .nav-link:hover{
  color:#37b65e;
}

.navbar-dark .navbar-nav .nav-link.active{
  color:#37b65e;
}
.navbar-dark .navbar-toggler {
  background-color: rgb(40, 177, 40);
  border-color: #37b65e;
  border-radius: 12px;

}


.breadcrumb {
  background-color:rgb(133, 231, 242);
}
.breadcrumb-item.active::before, .breadcrumb-item.active {
  color:white;
}

.parallax2 {
  background-image: url("/assets/images/curacao_downtown.jpg");

  /* Set a specific height */
  min-height: 300px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.opener{

  margin:0 0px 0 0 ;
  padding: 0;
  display: flex;
  position: relative;
  padding: .5rem 1rem;
  box-sizing: border-box;
  justify-content: flex-start;
  border-color:transparent;
  width: 100%;
  flex-flow: row nowrap;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;




}
.poverlay{

  background-image:
  linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0));
  z-index: -2;
}

.contact{
  font-size:10px; 
  text-align: center; 
}

.rights{
  font-size:16px; 
  text-align: center; 
}

.parallax1 {

  background-image: url("/assets/images/curacao_channel.jpg");

  


  /* Set a specific height */
  min-height: 500px;
  opacity: 99%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.btn {
  color: #fff;
  background-color: #37b65e;
  border: 0; 
  padding: 7% 15% 7% 15 ;
  margin:  0.5em ;
  border-style: solid; 
}

.btn:hover{
  
  background-color: white; 
  color: rgb(40, 177, 40);
  border: 1px;
  border-color:#37b65e;
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%); 
}

.page-enter-active {
  opacity: 1; 
  transform: translateX(0%); 
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1; 
  transform: translateX(0%);   
}

.page-exit-active {
  opacity: 0.01; 
  transform: translateX(100%); 
  transition: all 300 ms ease-out;
}


.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.counter{
  margin-top: 2em;
  margin-bottom: 2em;
  align-items: center;
  justify-content: center;
  color: #37b65e;
  border-color:transparent;
}

.threePanel{
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #EEEEEE;
  border-color:transparent;
}

.projects{
  padding: 2em;
  background-color: #EEEEEE;
  border-color:transparent;
}

.carouselImg{

  margin-bottom: 1em;
  padding: 1em;
  background-color: #EEEEEE;
  border-color:transparent;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  
}

button {
  white-space: nowrap;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  color:rgb(133, 229, 240);
  background-color: white;
  border-color: rgb(133, 229, 240);
  border-style: solid; 
  border-width: 1px;
}

address.button{
 
    color:black;
    background-color: white;
}


button:disabled{
  border: 0;
  color: #fff;
  background-color: rgb(133, 229, 240);
  opacity: 0.5;
}

.react-calendar{
  width: 350px;
  max-width: 100%;
  color: black;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.500em;
}


button:hover {
  
  cursor: pointer;
  color: #fff;
  background-color: rgb(133, 229, 240);
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}


.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  min-width: 300px;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}


.StripeElement.PaymentRequestButton {
  padding: 0;
  height: 40px;
  min-width: 60px;
  display:block;

}



.StripeElement.PaymentRequestButton:disabled {
  color:rgb(116, 116, 116);
  background-color: rgb(116, 116, 116);
}


  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    /* border: 1px solid #a0a096; */
    border: 0;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    color: black;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: rgb(133, 231, 242);
    opacity: 0.5;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #e6e6e6;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  

  @media (max-width:1025px) {
    .parallax2 {
        background-attachment: inherit;
    }
  }


